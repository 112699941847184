/*
 *  Bootstrap $Alerts
 *
 */

.alert-success {
    .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
}
.alert-info {
    .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
}
.alert-warning {
    .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
}
.alert-danger {
    .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
}
.alert {
    padding: 10px 15px;
    .rounded(@border-radius-base);
}
.alert-dismissable .close {
    right: 0;
}