/*
 *  $Presentation styles
 *
 */

pre {
    color: @code-color;
    background-color: #EFF2F5;
    border: 1px solid @border-color;
    .rounded(@border-radius-base);
    font-size: 11px;
    font-family: @font-family-monospace;
}