/*
 * $404_500, Signin, Signup and lockscreen styles
 *
 */

.app-error {
    width: 100%;
    height: 100%;
}
@media screen and (min-width: @screen-sm-min) {
    .error-container,
    .app-user {
        position: absolute;
        top: 20%;
        left: 50%;
        width: 100%;
        max-width: 400px;
        margin: 0 0 0 -200px;
        .rounded(@border-radius-base);
    }
}
@media (max-width: @screen-xs-max) {
    .error-container,
    .app-user {
        margin: 20px 15px;
    }
}
.error-number {
    font-size: 150px;
    font-weight: 300;
    line-height: 1;
}
.error-nav li {
    display: inline;
    padding: 0 10px;
}
.error-nav li a {
    color: @text-color;
}
/* lockscreen input width */
.lockcode {
    max-width: 280px;
    display: block;
    margin: 0 auto;
}