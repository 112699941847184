/*
 * $Off Canvas
 *
 */

@media (max-width: @screen-xs-max) {
    .layout {
        .transition(transform 300ms ease);
    }
    /* Off-Canvas overlay */
    .site-overlay {
        display: none;
    }
    .off-canvas .site-overlay,
    .site-overlay.active {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background: transparent;
    }
    .off-canvas .layout {
        position: fixed!important;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: 0!important;
    }
    .csstransforms3d .move-left .layout {
        .translate3d(@sidebar-width-sm, 0, 0);
    }
    .csstransforms3d .move-right .layout {
        .translate3d(-@sidebar-width-sm, 0, 0);
    }
    .app:not(.off-canvas) .header-fixed ~ .layout .canvas-left,
    .app:not(.off-canvas) .header-fixed ~ .layout .canvas-right {
        top: 50px;
    }
    .canvas-left,
    .canvas-right {
        position: fixed;
        top: 0;
        overflow-x: hidden;
        overflow-y: auto;
        width: @sidebar-width-sm!important;
        display: block;
        clear: both;
        top: 0;
        bottom: 0;
        z-index: 9999;
    }
    .csstransforms3d .canvas-left {
        left: 0;
        .translate3d(-100%, 0, 0);
    }
    .no-csstransforms3d .canvas-left {
        left: -@sidebar-width-sm;
    }
    .csstransforms3d .canvas-right {
        right: 0;
        .translate3d(100%, 0, 0);
    }
    .no-csstransforms3d .canvas-right {
        right: -@sidebar-width-sm;
    }
    .no-csstransforms3d .move-right .layout {
        left: -@sidebar-width-sm;
    }
    .no-csstransforms3d .move-left .layout {
        left: @sidebar-width-sm;
    }
    .off-canvas .canvas-right,
    .off-canvas .canvas-left {
        -webkit-overflow-scrolling: touch!important;
    }
    .off-canvas .main-content > .header.header-fixed ~ .content-wrap {
        padding-top: 15px;
    }
    .off-canvas .main-content > .header.header-fixed ~ .content-wrap.no-padding {
        padding-top: 0;
    }
    .off-canvas .main-content .header {
        position: relative;
        display: block;
    }

    .off-canvas .portfolio-ajax .portfolio-container {
        padding-top: 0;
    }
}