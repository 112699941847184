/*
 *  Various $Charts plugin styles
 *
 */

 /* Dashboard Chart */
.dashboard-chart .panel-heading{
    padding-bottom: 30px
}
.dashboard-chart svg {width:100%;}
 @media screen and (min-width: @screen-sm-min) {

     .dashboard-chart {
        position: relative;
     }

    .dashboard-chart .panel-footer {
        text-align: center;
        small {
            display: block;
        }
        .row {
        margin-bottom: 10px;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .dashboard-chart .panel-footer {
        .row {
        margin: 0;
        }
        .row > * {
            padding: 5px;
            margin: 5px 0;
        }
        span {
            display: block
        }
    }
}


/* Morris Tooltip */
.morris-hover {
    position: absolute;
    z-index: @zindex-chart-tooltip;
}
.morris-hover.morris-default-style {
    .rounded(@border-radius-base);
    padding: 6px;
    color: #ffffff;
    font-size: 12px;
    text-align: center
}
.morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: bold;
    margin: 0.25em 0
}
.morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: 0.1em 0
}

/* Sparkline Tooltip */
#jqstooltip {
    display: inline-block;
    margin: 0!important;
    padding: 5px 70px 15px 5px;
    width: 100%;
    border: none;
    .rounded(@border-radius-base);
    text-align: center;
}

/* Flot Charts */
#tooltip {
    position: absolute;
    display: none;
    padding: 5px 10px;
    border: 0;
    line-height: 1
}
#tooltip:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px;
}

.piechart {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    text-align: center;
}
.piechart canvas {
    position: absolute;
    top: 0;
    left: 0;
}
.piechart > span > span {
    margin: 67px 0;
    display: block;
}
.piechart .percent {
    display: block
}
.piechart .percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .5em;
}

/* Chart dimensions */
.chart {
    border: 0;
    width: 100%;
    height: 200px;
}
.chart-legend.first {
    padding-top: 80px;
}

/* Center canvas elements */
canvas {
    display: block!important;
    margin: 0 auto!important;
}

/*
 *  Maps
 *
 */
#map {
    width: 100%;
    height: 100%;
    background: transparent;
    min-height: 300px;
    position: relative;
}
#map .jvectormap-container {
    min-height: 300px;
}
.map-footer {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    background: rgba(255, 255, 255, .8);
    .rounded(2px);
}
/* Google Maps top Address bar */
.geo_address {
    width: 300px;
}