/*
 *  $Chat Box
 *
 */
.chat-box {
    .panel {
        border: 1px solid @border-color;
    }
    .chat-user {
        padding: 10px 0;
        clear: both;
    }
    .chat-user.right .chat-avatar img {
        float: right;
    }
    .chat-user .chat-avatar.pull-right {
        padding-right: 0;
        padding-left: 10px;
    }
    .chat-avatar {
        float: left;
        width: 50px;
        display: block;
        padding-right: 10px;
    }
    .chat-avatar img {
        width: 32px;
    }
    .message {
        position: relative;
        margin-left: 55px;
        span {
            color: #555;
            font-weight: bold;
        }
        p {
            padding-top: 5px;
        }
    }
    .message:before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        border-right: 6px solid @border-color;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        top: 10px;
        margin-left: -6px;
    }
    .message:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        border-right: 6px solid #fff;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        top: 10px;
        margin-left: -5px;
    }
    .chat-user.right .message {
        position: relative;
        margin-left: 0;
        margin-right: 55px;
    }
    .chat-user.right .message:before {
        left: 100%;
        margin-right: 6px;
        margin-left: 0;
        border-right: 6px solid transparent;
        border-left: 6px solid @border-color;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }
    .chat-user.right .message:after {
        left: 100%;
        margin-right: 6px;
        margin-left: -1px;
        border-right: 6px solid transparent;
        border-left: 6px solid #fff;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }
}

/*
 *  Mail Reply
 *
 */
.chat-bottom,
.mail-reply {
    padding: 10px;
    height: 100%;
    background: #ffffff;
}
.chat-reply:focus,
.mail-reply:focus {
    outline: 0;
}
.mail-reply {
    height: 60px;
    position: relative;
    .rounded(@border-radius-base);
}

/*
 *  Chat
 *
 */
.chat-bottom {
    min-height: 60px;
    max-height: 60px;
    background: @brand-default;
    padding: 0;
    width: 100%;
}
@media screen and (min-width: @screen-sm-min) {
    .chat-bottom {
        position: absolute;
        bottom: 0;
    }
}
@media (max-width: @screen-xs-max) {
    .chat-bottom {
        position: relative;
    }
}
.chat-reply {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    top: 15px;
    .rounded(@border-radius-base);
    padding: 5px;
}
