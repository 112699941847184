/*
 *
 * Main stylesheet for $Switchery.
 * http://abpetkov.github.io/switchery/
 *
 */

.switchery {
    background-color: #ffffff;
    border: 1px solid @border-color;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    position: relative;
    vertical-align: middle;
    width: 50px;
}
.switchery > small {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    height: 28px;
    position: absolute;
    top: 0;
    width: 28px;
}