
.border-radius(@topright: 0,@bottomright: 0,@bottomleft: 0,@topleft: 0) {
    -webkit-border-top-right-radius: @topright;
    -webkit-border-bottom-right-radius: @bottomright;
    -webkit-border-bottom-left-radius: @bottomleft;
    -webkit-border-top-left-radius: @topleft;
    -moz-border-radius-topright: @topright;
    -moz-border-radius-bottomright: @bottomright;
    -moz-border-radius-bottomleft: @bottomleft;
    -moz-border-radius-topleft: @topleft;
    border-top-right-radius: @topright;
    border-bottom-right-radius: @bottomright;
    border-bottom-left-radius: @bottomleft;
    border-top-left-radius: @topleft;
}

.rounded(@radius: 2px) {
    -webkit-border-radius: @radius;
        -moz-border-radius: @radius;
            border-radius: @radius;
}

.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow;
        box-shadow: @shadow;
}

#gradient {
  .striped(@color: #555; @angle: 45deg) {
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  }
}

.transition(@transition) {
    -webkit-transition: @transition;
        -moz-transition: @transition;
            -o-transition: @transition;
                transition: @transition;
}
.transition-delay(@transition-delay) {
    -webkit-transition-delay: @transition-delay;
        -moz-transition-delay: @transition-delay;
            -o-transition-delay: @transition-delay;
                transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
    -webkit-transition-duration: @transition-duration;
        -moz-transition-duration: @transition-duration;
            -o-transition-duration: @transition-duration;
                transition-duration: @transition-duration;
}

.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
        -moz-transform: rotate(@degrees);
            -ms-transform: rotate(@degrees);
                -o-transform: rotate(@degrees);
                    transform: rotate(@degrees);
}
.scale(@ratio) {
    -webkit-transform: scale(@ratio);
        -moz-transform: scale(@ratio);
            -ms-transform: scale(@ratio);
                -o-transform: scale(@ratio);
                    transform: scale(@ratio);
}
.translate(@x, @y) {
    -webkit-transform: translate(@x, @y);
        -moz-transform: translate(@x, @y);
            -ms-transform: translate(@x, @y);
                -o-transform: translate(@x, @y);
                    transform: translate(@x, @y);
}

.translate3d(@x, @y, @z) {
    -webkit-transform: translate3d(@x, @y, @z);
        -moz-transform: translate3d(@x, @y, @z);
            -o-transform: translate3d(@x, @y, @z);
                transform: translate3d(@x, @y, @z);
}

.user-select(@select) {
    -webkit-user-select: @select;
        -moz-user-select: @select;
            -ms-user-select: @select;
                -o-user-select: @select;
                    user-select: @select;
}

.opacity(@opacity) {
    opacity: @opacity;
    @opacity-ie: ( @opacity * 100 );
    filter: ~"alpha(opacity=@{opacity-ie})";
}

.placeholder(@color: @input-color-placeholder) {
    &:-moz-placeholder { color: @color; }
    &::-moz-placeholder { color: @color; }
    &:-ms-input-placeholder { color: @color; }
    &::-webkit-input-placeholder { color: @color; }
}

.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border;) {
    border-color: @border;

    & > .panel-heading {
        color: @heading-text-color;
        background-color: @heading-bg-color;
        border-color: @heading-border;

        + .panel-collapse .panel-body {
            border-top-color: @border;
        }
        & a {
            color: @heading-text-color;
        }
    }
    & > .panel-footer {
        + .panel-collapse .panel-body {
            border-bottom-color: @border;

        }
    }
}

.alert-variant(@background; @border; @text-color) {
    background-color: @background;
    border: 0;
    color: @text-color;

    hr {
        border-top-color: darken(@border, 5%);
    }

    .alert-link {
        color: darken(@text-color, 10%);
    }
}

.table-row-variant(@state; @background; @border) {
    .table > thead > tr,
    .table > tbody > tr,
    .table > tfoot > tr {
        > td.@{state},
        > th.@{state},
        &.@{state} > td,
        &.@{state} > th {
            background-color: lighten(@background, 25%);
            border-color: lighten(@border, 20%);
        }
    }
    .table-hover > tbody > tr {
        > td.@{state}:hover,
        > th.@{state}:hover,
        &.@{state}:hover > td {
            background-color: darken(@background, 28%);
            border-color: darken(@border, 28%);
        }
    }
}

.label-variant(@color) {
    background-color: @color;
    &[href] {
        &:hover,
        &:focus {
            background-color: darken(@color, 10%);
        }
    }
}

.progress-bar-variant(@color) {
    background-color: @color;
    .progress-striped & {
        #gradient > .striped(@color);
    }
}

.ribbon-variant(@color) {
    .banner::after,
    .banner::before {
        background-color: darken(@color, 10%);
    }
    .text::before,
    .text::after,
    .no-css-transforms & .text {
        background-color: @color;
    }
}

.form-control-validation(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
    .help-block,
    .control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        color: @text-color;
    }

    .form-control {
        border-color: @border-color;
        .box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
        &:focus {
            border-color: darken(@border-color, 10%);
            @shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten(@border-color, 20%);
            .box-shadow(@shadow);
        }
    }

    .input-group-addon {
        color: @text-color;
        border-color: @border-color;
        background-color: @background-color;
    }

    .form-control-feedback {
        color: @text-color;
    }
}

.parsley-validation(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
    border-color: @border-color;
    .box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
    &:focus {
        border-color: darken(@border-color, 10%);
        @shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten(@border-color, 20%);
        .box-shadow(@shadow);
    }
}

.text-variant(@color-class; @text-color;) {
    .text-@{color-class},
    .text-@{color-class} a  {
        color: @text-color;
        &:focus {
            color: darken(@text-color, 15%);
        }
    }
}

.bg-variant(@text-color; @background-color) {
    & {
        background-color: @background-color;
        color: mix(@background-color, @text-color, 30%);
    }

    & > a {
        color: mix(@background-color, @text-color, 30%);
        &:active, &:focus, &:hover {
            color: mix(@background-color, @text-color, 40%);
            text-decoration: none;
            outline: 0;
        }
    }

    .nav > li {
        &:hover > a,
        &:focus > a,
        &:active > a,
        &.active > a,
        & > a:hover,
        & > a:focus {
            background-color: darken(@background-color, 3%);
        }
    }
}

.button-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open .dropdown-toggle& {
        color: @color;
        background-color: darken(@background, 5%);
        border-color: darken(@border, 5%);
    }

    a& {
        color: @color;
    }

    &:active,
    &.active,
    .open .dropdown-toggle& {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: @background;
            border-color: @border
        }
    }
}

// Color skin generator
.color-gen(@palette-color, @header-bg, @header-color, @brand-bg, @brand-color, @sidebar-bg, @sidebar-color, @sel-color) {

    /* Selected text */
    ::-moz-selection {
        background: @palette-color;
        color: @sel-color;
        text-shadow: none;
    }
    ::selection {
        background: @palette-color;
        color: @sel-color;
        text-shadow: none;
    }
    /*********************/

    /* General anchor states */
    a {
        &:active, &:focus, &:hover {
            color: darken(@palette-color, 10%);
            text-decoration: none;
            outline: 0;
        }
    }
    /*********************/

    /* Layout top header */
    .app > .header,
    .app > .container > .header {
        background-color: @header-bg;
    }
    .header .brand {
        background-color: @brand-bg;
        a {
            color: @brand-color;
        }
    }
    .header .navbar-toggle {
        color: @brand-color;
    }
    .header .nav > li > a,
    .header .bg-none {
        color: @header-color;
    }
    .header .form-control {
        .placeholder(lighten(@header-color, 10%));
    }
    /*********************/

    /* Layout Sidebar */
    .sidebar,
    .chat-sidebar {
        background-color: @sidebar-bg;
        color: @sidebar-color;
         a{
            color: @sidebar-color;
        }
    }
    .main-navigation >ul>li>a{
        color: @sidebar-color;
    }
    .app:not(.small-sidebar) .collapsible .main-navigation>ul>li>ul>li>a {
        color: @sidebar-color;
    }
    .app:not(.small-sidebar) .collapsible .main-navigation>ul>li>ul>li.active {
        background: darken(@sidebar-bg, 10%);
    }
    .main-navigation:not(.app-navigation) > ul {
        & > li.active,
        & > li:hover,
        & > li.open {
            background: darken(@sidebar-bg, 2.5%);
        }
    }
    /*********************/

    /* Skin Gallery style */
    .spinner-loader {
        background: @palette-color;
        background-color: fade(@palette-color, 90%);
        & > div {
            background-color: mix(@palette-color, #fff, 30%);
        }
    }
    .loader {
        color: lighten(@palette-color, 10%);
    }
    .gallery {
        .overlay {
            background: @palette-color;
            background-color: fade(@palette-color, 90%);
        }
    }
    /*********************/

    /* Skin button style */
    .btn-color {
        .button-variant(#fff; @palette-color; @palette-color);
    }
    /*********************/

    /* Skin text color style */
    .color {
        color: @palette-color;
    }
    /*********************/

    /* Skin table style */
    .panel-color.pricing-table-2 {
        .plan-price span {
            border-color: @palette-color;
        }
        &:hover .plan-price span {
            background-color: @palette-color;
            color: mix(@palette-color, #fff, 30%);
        }
    }
    /*********************/

    /* Skin nestable style */
    #nestable2 .dd-handle {
        color: mix(@palette-color, #fff, 30%);
        border: 1px solid @palette-color;
        background: @palette-color;
    }
    #nestable2 .dd-handle:hover {
        background: lighten(@palette-color, 5%);
    }
    /*********************/

    /* Skin slider style */
    .slider-color .slider-selection
    {
        background-color: @palette-color;
    }
    /*********************/

    /* Skin panel style */
    .panel-color {
        .panel-variant(@palette-color; mix(@palette-color, #fff, 30%); @palette-color; @palette-color);
    }
    /*********************/

    /* Skin label style */
    .label-color {
        .label-variant(@palette-color);
    }
    /*********************/

    /* Skin background color */
    .bg-color {
        .bg-variant(mix(@palette-color, #fff, 30%); @palette-color);
    }
    /*********************/

    /* Skin pagination style */
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus,
    .dataTables_wrapper .pagination .active a {
        border-color: @palette-color;
        background-color: @palette-color;
        color: #fff;
    }
    /*********************/

    /* Skin progress bar style */
    .progress-bar-color {
        background-color: @palette-color;
    }
    /*********************/

    /* Skin chart elements */
    .morris-hover.morris-default-style {
        background-color: @palette-color;
        border-color: transparent;
        background-color: fade(@palette-color, 90%);
        a {
            color: mix(@palette-color, #fff, 30%);
        }
    }
    #jqstooltip {
        background: @palette-color;
    }
    #tooltip {
        background: @palette-color;
        color: mix(@palette-color, #fff, 30%);
    }
    #tooltip:before {
        border-color: transparent @palette-color transparent transparent;
    }
    /*********************/
}


.font-gen(@body; @heading) {
    body {
        font-family: @body;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    .heading-font {
        font-family: @heading;
    }
}
