/*
 * CSS $Ribbons
 *
 */

.ribbon {
    position: absolute;
    top: 0;
    right: 0;
}
.ribbon .text,
.ribbon .banner,
.ribbon a {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
.ribbon a {
    color: #fff;
    text-decoration: none;
}
.no-css-transforms .ribbon {
    display: none;
    visibility: hidden;
}
.ribbon .banner {
    .rotate(45deg);
    color: #fff;
    display: block;
    float: right;
    position: relative;
    right: -14px;
    top: 22px;
    width: 100px;
}
.ribbon-small .banner {
    right: -8px;
    top: 15px;
    width: 65px;
}
.ribbon-large .banner {
    right: -20px;
    top: 32px;
    width: 150px;
}
.ribbon .banner::after,
.ribbon .banner::before {
    content: '';
    display: block;
    height: 12px;
    position: absolute;
    width: 30px;
}
.ribbon-small .banner::after,
.ribbon-small .banner::before {
    height: 6px;
    width: 20px;
}
.ribbon-large .banner::after,
.ribbon-large .banner::before {
    height: 18px;
    width: 45px;
}
.ribbon .banner::before {
    -webkit-transform: skewY(-45deg) translate(50%, 15px);
    -moz-transform: skewY(-45deg) translate(50%, 15px);
    -ms-transform: skewY(-45deg) translate(50%, 15px);
    -o-transform: skewY(-45deg) translate(50%, 15px);
    -webkit-transform-origin: 100% center;
    -moz-transform-origin: 100% center;
    -ms-transform-origin: 100% center;
    -o-transform-origin: 100% center;
    left: -45px;
}
.ribbon-small .banner::before {
    top: -5px;
    left: -30px;
}
.ribbon-large .banner::before {
    top: 9px;
    left: -68px;
}
.ribbon .banner::after {
    -webkit-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
    -moz-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
    -ms-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
    -o-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
    -webkit-transform-origin: 0 center;
    -moz-transform-origin: 0 center;
    -ms-transform-origin: 0 center;
    -o-transform-origin: 0 center;
    right: -17px;
}
.ribbon-small .banner::after {
    top: 18px;
    right: -12px;
}
.ribbon-large .banner::after {
    top: 45px;
    right: -26px;
}
.ribbon .text {
    position: relative;
    z-index: 2;
    padding: 6px 0;
    min-height: 18px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}
/* small text */

.ribbon-small .text {
    padding: 3px 0;
    font-size: 8px;
    min-height: 14px;
    line-height: 14px;
}
/* large text */

.ribbon-large .text {
    padding: 9px 0;
    font-size: 18px;
    min-height: 28px;
    line-height: 28px;
}
.ribbon .text::before,
.ribbon .text::after {
    content: '';
    display: block;
    height: 30px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
/* small text */

.ribbon.ribbon-small .text::before,
.ribbon.ribbon-small .text::after {
    height: 20px;
}
/* large text */

.ribbon.ribbon-large .text::before,
.ribbon.ribbon-large .text::after {
    height: 46px;
}
.ribbon .text::before {
    -webkit-transform: translateX(-15%) skewX(-45deg);
    -moz-transform: translateX(-15%) skewX(-45deg);
    -ms-transform: translateX(-15%) skewX(-45deg);
    -o-transform: translateX(-15%) skewX(-45deg);
}
.ribbon .text::after {
    -webkit-transform: translateX(15%) skewX(45deg);
    -moz-transform: translateX(15%) skewX(45deg);
    -ms-transform: translateX(15%) skewX(45deg);
    -o-transform: translateX(15%) skewX(45deg);
}
// Ribbon Color Variations
 .ribbon-success {
    .ribbon-variant(@brand-success);
}
.ribbon-info {
    .ribbon-variant(@brand-info);
}
.ribbon-warning {
    .ribbon-variant(@brand-warning);
}
.ribbon-danger {
    .ribbon-variant(@brand-danger);
}
.ribbon-dark {
    .ribbon-variant(@brand-danger);
}