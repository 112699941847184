/*
 * Bootstrap $Popover
 *
 */

.popover {
    border: 1px solid @popover-border-color;
    .box-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
    .popover-title {
        border-bottom: 1px solid @border-color;
        .border-radius(@border-radius-base, 0, 0, @border-radius-base);
        background-color: @popover-header;
    }
}
