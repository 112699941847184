/*
 *  Bootstrap $Buttons
 *
 */

.btn {
    outline: 0;
    .rounded(@btn-border-radius);
    .transition(.25s linear);
    font-weight: @btn-font-weight;
    &:focus,
    &:active,
    &.active {
        outline: 0;
    }
}
.btn:active,
.btn.active {
    .box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.05));
    outline: 0;
}

/* Rounded button styles */
.btn-rounded,
.btn-rounded a,
.btn-rounded .btn,
.input-rounded {
    .rounded(40px);
}

/* Button Groups */
.btn-group-vertical > .btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: @btn-border-radius;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-right-radius: @btn-border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

/*
 *  Default Button Style
 *
 */
.btn-default {
    .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

/*
 *  Primary Button Style
 *
 */
.btn-primary {
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}

/*
 *  Warning Button Style
 *
 */
.btn-warning {
    .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}

/*
 *  Success Button Style
 *
 */
.btn-success {
    .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}

/*
 *  Info Button Style
 *
 */
.btn-info {
    .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}

/*
 *  White Button Style
 *
 */
.btn-white {
    .button-variant(@btn-white-color; @btn-white-bg; @btn-white-border);
}

/*
 *  Danger Button Style
 *
 */
.btn-danger {
    .button-variant(@brand-white; @brand-danger; @brand-danger);
}

/*
 *  Outline Button Style
 *
 */
.btn-outline,
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active,
.open .dropdown-toggle.btn-outline {
    background: transparent;
    color: @text-color!important;
}