/*
 * Image $Gallery
 *
 */

.gallery {
    .item {
        position: relative;
        overflow: hidden;
        padding: 0;
        height: auto;
    }
    a {
        display: block;
    }
    .item:hover {
        & a > .overlay {
            .opacity(1);
        }
    }
    img {
        position: relative;
        z-index: 4;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        z-index: 999;
        .opacity(0);
        display: block;
        .transition(opacity 600ms);
        .title {
            display: block;
            font-size: 16px;
        }
    }
    .overlay-text {
        width: 100%;
        color: #fff;
        text-align: center;
        position: absolute;
        top: 50%!important;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
.portfolio-ajax {
    display: none;
    .portfolio-container {
        padding-top: 50px;
        background-color: #fff;
    }
    .porfolio-controls {
        background-color: #fff;
        height: 50px;
        z-index: 99999;
        padding: @size-base;
        border-top: 1px solid @border-color;
        border-bottom: 1px solid @border-color;
        a {
            width: 33.3333%;
            display: block;
            float: left;
            vertical-align: middle;
            &.prev-portfolio-item {
                text-align: left;
            }
            &.next-portfolio-item {
                text-align: right;
            }
            &.close-view {
                text-align: center;
            }
            &.disabled {
                color: @black-40;

            }
        }
    }
    .project-media {
        text-align: center;
        margin-bottom: 30px;
    }
    .portfolio-content {
        padding: 30px;
    }
    .loader {
        opacity: 0;
        display: none;
    }
    &.loading {
        .loader {
            opacity: 1;
            filter: alpha(opacity=100);
            display: block;
        }
        .portfolio-container {
            .opacity(0);
        }
    }
    &.loaded {}
}
@media screen and (min-width: @screen-sm-min) {
    .portfolio-ajax {
        .portfolio-container {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .portfolio-ajax {
        .porfolio-controls {
            height: 50px;
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 99999;
        }
    }
}
@media (max-width: @screen-xs-max) {
    .porfolio-controls {
        width: 100%;
        z-index: 9999;
        position: fixed;
    }
}
.spinner-loader {
    position: absolute;
    display: initial;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    .rounded(50%);
    margin: -50px 0 0 -50px;
    z-index: 9999;
    text-align: center;
    line-height: 100px;
}
.spinner-loader > div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.spinner-loader .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.spinner-loader .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@media (max-width: @screen-xs-max) {
    .spinner-loader {
        position: fixed;
    }
}
@-webkit-keyframes bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0.0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}
@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}