/*
 *  Demo specific styles.
 *
 * These can be removed
 */

.bs-demo .btn {
    margin-bottom: 5px
}
.tool-button .btn {
    margin-right: 5px
}
.demo-button .btn {
    min-width: 114px;
    margin-bottom: 5px
}
.demo > .dropdown {
    float: left;
}
.demo > .dropdown > .dropdown-menu {
    position: static;
    display: block;
    margin-bottom: 5px;
}
.demo-button2 button {
    margin: 0 5px 10px 0;
}
.fontawesome-list a {
    display: block;
    margin-left: 30px;
    height: 32px;
    color: @text-color;
    font-size: 1em;
    line-height: 32px;
}
.fontawesome-list .fa {
    position: absolute;
    top: 9px;
    left: 20px;
    .transition(all 150ms linear);
}
.fontawesome-list a:hover .fa {
    top: 5px;
    color: @brand-success;
    font-size: 1.5em;
}
.form-horizontal.bordered-group {
    .form-group {
        border-bottom: 1px solid #E7EBF3;
        padding-bottom: 15px;
    }
    .form-group:last-child {
        border-bottom: 1px solid transparent;
        padding: 0;
    }
}