/*
 * Bootstrap $Badge
 *
 */

.badge.badge-top {
    position: relative;
    top: -10px;
    padding: 3px 5.5px;
    margin-left: -10px;
}
.badge-opac {
    background: transparent;
    border: 1px solid rgba(0,0,0,.2);
}