/*
 *  Bootstrap $Pagination
 *
 */

.pagination {
    .rounded(@border-radius-base);
}
.pagination > li > a,
.pagination > li > span {
    border-color: @pagination-border;
}
.pagination > li > a:hover,
.pagination > li > span:hover {
    background-color: @pagination-hover-bg;
    border-color: darken(@pagination-hover-bg, 10%);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    .border-radius(0, 0, @border-radius-base, @border-radius-base);
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    .border-radius(@border-radius-base, @border-radius-base, 0, 0);
}
