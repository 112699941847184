/*
 * Bootstrap $Dropdown
 *
 */

.dropdown-menu {
    margin: 0;
    padding: 0;
    .rounded(@border-radius-base);
    border: 1px solid @dropdown-border;
    z-index: @zindex-dropdown;
    .box-shadow(none);
    & > li {
        & > a {
            margin: 0;
            .rounded(@border-radius-base);
            font-size: 13px;
            line-height: 22px;
            color: @dropdown-link-color;
        }
        & > a:hover {
            background: @dropdown-link-hover-bg;
        }
    }
    .divider {
        margin: 2px 0;
        height: 1px;
        background-color: @dropdown-divider-bg;
    }
    & > .active > a,
    & > .active > a:hover,
    & > .active > a:focus {
        background-color: @dropdown-link-active-bg;
        color: @dropdown-link-color;
    }
}
.main-navigation .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    border: 1px solid #D1D1D1;
    border-left: 0;
    a {
        margin: 0!important;
        padding: 3px 20px!important;
        width: 100%;
        font-size: 13px;
    }
}
.right-sidebar .main-navigation .dropdown-menu {
    border-left: 1px solid #D1D1D1!important;
    border-right: 0!important;
}
.main-navigation .show-on-hover {
    position: relative;
    top: 0;
    display: block;
    margin: 0;
    border: 0;
    .rounded(0);
    background: transparent;
    .box-shadow(none);
    font-size: inherit;
    li {
        padding: 0!important;
        border-bottom: 0!important;
    }
    & > .dropdown-menu li a {
        text-align: left!important;
    }
}
.show-on-hover:hover .dropdown-menu {
    display: block;
}
.dropdown.open .dropdown-menu {
    display: block;
}
@media screen and (min-width: @screen-sm-min) {
    .right-sidebar .main-navigation .dropdown-menu {
        left: auto;
        right: 100%;
        border-width: 1px!important;
        border-right: 0!important;
    }
}
@media (max-width: @screen-xs-max) {
    .main-navigation .dropdown-menu {
        .rounded(0);
        border: 0!important;
        .box-shadow(none);
    }
    .show-on-hover.open .dropdown-menu,
    .collapse-open {
        display: block;
    }
    .main-navigation .show-on-hover.open .dropdown-menu,
    .navbar-collapse .show-on-hover.open .dropdown-menu{
        display: block!important;
    }
    .main-navigation .show-on-hover:hover .dropdown-menu,
    .navbar-collapse .show-on-hover:hover .dropdown-menu {
        display: none;
    }
    .show-on-hover.open .dropdown-menu {
        width: 100%;
    }
    .dropdown-menu.dropdown-menu {
        top: 100%;
        left: 0;
    }
}