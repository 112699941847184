/*
 * $Content Layout
 *
 * Core theme layout styles
 */

/* main app area */
.app {
    overflow: hidden;
}
.container {
    padding: 0;
}

@media screen and (min-width: @screen-sm-min) {
    .container,
    .app {
        height: 100%;
        position: relative;
        padding: 0;
    }
}

.main-content {
    background: @content-background;
    .content-wrap {
        padding: @size-base;
        &.with-footer {
            padding-bottom: 50px;
        }
    }
    > footer {
        height: 50px;
        padding: 0 @size-base;
        background-color: @brand-default;
        p {
            display: inline-table;
            margin: 19px 0;
            line-height: 1;
        }
    }
}
.main-content .subscription {
    padding: 50px @size-base;
    .rounded(@border-radius-base);
}

.main-content .header .nav > li:active > a,
.main-content .header  .nav > li.active > a {
    background-color: @content-background;
    color: @text-color;
}

@media screen and (min-width: @screen-sm-min) {
    .main-content .subscription {
        width: 600px;
        height: 440px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -300px;
        margin-top: -220px;
        img {
            width: 100%
        }
    }
    .main-content > footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }
}


/* .layout */
.layout .header {
    z-index: @zindex-content-header;
}

.app .header-fixed:not(.visible-xs) + .layout {
    padding-top: 50px;
}

@media screen and (min-width: @screen-sm-min) {
    .app .layout {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
    .app .layout > section,
    .app .layout > aside {
        float: left;
        height: 100%;
        position: relative;
    }
    .app .layout > section.main-content {
        position: relative;
        float: none;
        width: auto;
        overflow: hidden;
    }
    .app .layout > section:before,
    .app .layout > aside:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
    }
}

@media (max-width: @screen-xs-max) {
    .app .header-fixed.visible-xs + .layout {
        padding-top: 50px;
    }
    .app .layout {
        position: relative;
        width: 100%;
    }
}


.slimScrollDiv,
.slimscroll {
    height: 100%!important
}

/* .main-content */
@media screen and (min-width: @screen-sm-min) {
    .main-content {
        width: 100%;
        height: 100%;
    }
    .content-wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .header + .content-wrap {
        top: 50px
    }
    .content-wrap.bottom-50 {
        bottom: 50px;
    }
    .main-content .chat-window {
        bottom: 60px!important;
    }
    .slimscroll {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@media (max-width: @screen-xs-max) {
    .main-content > .header {
        padding: 0;
    }
    .main-content > .header > ul > li {
        display: block;
        float: left;
    }
    .main-content > .header.header-fixed + .content-wrap {
        padding-top: 65px
    }
    .main-content > .header.header-fixed + .content-wrap.no-padding {
        padding-top: 50px
    }
}

.chat-window .panel {
    margin-bottom: 1px;
}
@media screen and (min-width: @screen-sm-min) {
    .chat-window {
        padding: 0!important;
    }
}
@media (max-width: @screen-xs-max) {
    .content-wrap.chat-window {
        padding-left: 0;
        padding-right: 0;
    }
}

/* Chat panel */
.chat-sidebar .user {
    position: relative;
    i {
        position: absolute;
        right: 0;
        top: 10px;
    }
}
@media screen and (min-width: @screen-sm-min) {
    .chat-sidebar {
        width: 0;
    }
    .off-canvas .chat-sidebar {
        width: 300px;
    }
}

/* Loading wrapper */
.loader {
    position: relative;
    top: 50%;
    .translate(0, -50%);
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

/*
 *  Modal
 *
 */
.modal-content {
    .rounded(@border-radius-large);
    .box-shadow(none);
}

/*
 *  Well
 *
 */
.well {
    background-color: #FFFFFF;
    .rounded(@border-radius-base);

}

/*
 *  List group
 *
 */
.list-group-item:first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}
.list-group-item:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.list-group-item {
    border: 1px solid @border-color;
}

.search-results .result {
    margin-bottom: 20px;
    border-bottom: 1px solid @border-color;
    a {
        color: @brand-success;
    }
    h5 a{
        color: @text-color;
    }
}
