/*
 * $Reset
 * Basic global styles
 */

/* Body reset */
body {
    background: @body-background;
    color: @text-color;
    font-weight: 400;
    font-size: @font-size-base;
}

* {
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
font-weight: @headings-font-weight;
line-height: 1.1;
color: #414141;
}

/* Links */
a {
    color: @link-color;
    text-decoration: none;
    .transition(.25s);
    &:visited,
    &:active,
    &:focus,
    &:hover {
        color: @link-hover-color;
        text-decoration: none;
        outline: 0;
    }
}
ol,
ul {
    padding: 0;
    list-style: none;
}
ol {
    list-style: outside decimal;
}
.bullet {
    list-style-type: disc;
    margin-left: 15px;
}

/* Selection styles */
::-moz-selection,
::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/* hr restyled*/
hr {
    display: block;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid @hr-border;
}

/* Images and avatars */
img {
    max-width: 100%;
    height: auto;
}
img {
    -ms-interpolation-mode: bicubic;
}
.img-rounded {
    .rounded(@border-radius-base);
}
.img-circle {
    .rounded(50%);
}
.avatar {
    position: relative;
    display: inline-block;
    width: 100%;
}
.avatar-lg {
    width: 128px;
}
.avatar-md {
    width: 64px;
}
.avatar-sm {
    width: 32px
}
.avatar-xs {
    width: 16px;
}
.bordered-avatar {
    border: 6px solid #efefef;
}
.header .avatar {
    width: 30px
}

/* Remove contenteditable outlines */
[contenteditable],
[contenteditable]:hover,
[contenteditable]:active {
    outline: 0;
}

@media screen and (min-width: @screen-sm-min) {
    html,
    body {
        height: 100%;
    }
}