/*
 *  $Widgets
 *
 */

.post .list-group-item,
.panel.post .panel-heading {
    border-color: #1AADD1;
}
/* Profile Widget */
.profile-panel .panel-body {
    .pic {
        width: 125px;
    }
    .watermark {
        position: absolute;
        .opacity(0.2);
        color: #ffffff;
        cursor: pointer;
        font-size: 200px;
        left: -15px;
        .rotate(-15deg);
    }
}
/* Toggle */
.toggle-active {
    cursor: pointer;
}
.toggle-active.active {
    color: @brand-warning;
}
/* Weather Widget */
.weather-widget {
    position: relative;
    h2 {
        margin: 0;
        padding: 25px 0 0;
    }
    li {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        text-align: center
    }
    span {
        display: inline-block;
    }
    .current-date {
        padding: 39px 0;
    }
    .row {
        .rounded(2px);
    }
    ul canvas {
        display: inline-block!important;
        margin: 0 10px -3px 0!important;
    }
}
/* Dashboard Circular icon wrapper */
.circle-icon {
    float: left;
    margin-right: @size-base;
    width: 50px;
    height: 50px;
    .rounded(100%);
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    canvas.weather-icon {
        display: block;
        float: left;
    }
}
/* Photo Post Widget */
.imgpost-heading {
    position: relative;
    height: 200px;
    padding: 0;
    .bxslider {
        margin: 0;
        padding: 0;
    }
    .bx-wrapper, .bx-viewport, .bx-viewport, .bxslider, .bxslider li {
        height: 100%;
    }
    .cover {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
    }
}
/* Photo Widget */
.photobox-heading {
    background: #fff url(../img/cover.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 150px;
    padding: 0;
    .thumb {
        float: right;
        margin-right: 15px;
        margin-top: 15px;
        cursor: pointer;
    }
}
/* Settings Widget */
.settings-body li {
    b {
        display: block;
    }
    i {
        display: inline-block;
        float: left;
        font-size: 20px;
        line-height: 20px;
    }
}
/* Star Rating */
.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
    font-size: 20px
}
.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block
}
.rating span.star:hover {
    cursor: pointer
}
.rating span.star:before {
    content: "\f006";
    padding-right: 5px;
    color: #999
}
.rating span.star:hover:before,
.rating span.star:hover~span.star:before {
    content: "\f005";
    color: @brand-warning
}
/* Social Widget */
.widget-social .panel-footer > .row > *:first-child {
    .border-radius(0, 0, @border-radius-base, 0);
}
.widget-social .panel-footer > .row > *:last-child {
    .border-radius(0, @border-radius-base, 0, 0);
}